<template>
    <section
        v-if="parks && parks.length"
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Park Showcase</div>
                </div>
            </div>
        </div>

        <div
            class="c-park-showcase js-park-showcase"
            :class="[
                `t-${componentData.settings.theme}`,
                {
                    'has-no-padding': componentData.settings.remove_padding == true,
                },
            ]"
            :data-component-id="componentId">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <template v-if="componentData.title">
                            <h2
                                v-if="componentData.settings.heading_level == 'h2'"
                                class="c-park-showcase__title wow animate__animated animate__fadeIn"
                                v-html="componentData.title"></h2>

                            <h3
                                v-else-if="componentData.settings.heading_level == 'h3'"
                                class="c-park-showcase__title wow animate__animated animate__fadeIn"
                                v-html="componentData.title"></h3>

                            <div
                                v-else
                                class="c-park-showcase__title wow animate__animated animate__fadeIn"
                                v-html="componentData.title"></div>
                        </template>

                        <div :class="`c-park-showcase__parks has-${parks.length}-items s-swiper`">
                            <swiper-container
                                ref="containerRef"
                                :init="false"
                                :navigation-next-el="`.js-park-showcase[data-component-id=${componentId}] .js-swiper-button-next`"
                                :navigation-prev-el="`.js-park-showcase[data-component-id=${componentId}] .js-swiper-button-prev`"
                                :pagination-el="`.js-park-showcase[data-component-id=${componentId}] .js-swiper-pagination`">
                                <swiper-slide v-for="(park, index) in parks" :key="index">
                                   
                                    <NuxtLink
                                        class="c-park-showcase__item"
                                        :to="stripBaseUrl(park.landing_page)"
                                        :title="`Explore ${park.title}`">
                                        <div v-if="park.featured_image.sizes" class="c-park-showcase__item-media">
                                            <img :src="park.featured_image.sizes['600x600']" :alt="park.title" :title="park.title" />
                                        </div>

                                        <div class="c-park-showcase__item-name" v-html="park.title"></div>

                                        <div
                                            v-if="park.summary"
                                            class="c-park-showcase__item-summary s-wp-cms"
                                            v-html="park.summary"></div>

                                        <ul v-if="park.key_features" class="c-park-showcase__item-features">
                                            <li
                                                v-for="(feature, index) in park.key_features"
                                                :key="index"
                                                class="c-park-showcase__item-feature">
                                                <span v-html="feature.icon?.element"></span>
                                                <span v-html="feature.name"></span>
                                            </li>
                                        </ul>

                                        <div class="c-park-showcase__item-link c-button--slimline">
                                            <span>
                                                <i class="fa-solid fa-chevron-right"></i> Explore {{ park.title }}
                                            </span>
                                        </div>
                                    </NuxtLink>
                                </swiper-slide>
                            </swiper-container>

                            <div v-if="parks.length > 1" class="swiper-pagination js-swiper-pagination"></div>

                            <div v-if="parks.length > 2" class="swiper-navigation">
                                <button
                                    class="swiper-button-prev js-swiper-button-prev"
                                    aria-label="Previous slide"
                                    type="button">
                                    <i class="fa-sharp fa-chevron-left"></i>
                                </button>

                                <button
                                    class="swiper-button-next js-swiper-button-next"
                                    aria-label="Next slide"
                                    type="button">
                                    <i class="fa-sharp fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const config = useRuntimeConfig();
    const componentId = useId();
    const containerRef = ref(null);

    const swiper = useSwiper(containerRef, {
        draggable: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
        navigation: {
            enabled: true,
        },
        pagination: {
            clickable: true,
            enabled: true,
        },
        slidesPerView: 1,
        spaceBetween: 10,
    });

    // Loop through props.componentData.parks to build a comma-separated list of park IDs
    let parkIds = null;

    if (props?.componentData?.content && props?.componentData?.content == "custom") {
        parkIds = props?.componentData?.parks ? props.componentData.parks.map((park) => park.ID).join(",") : null;
    }

    const parks = ref([]);

    const getParks = async (parkIds) => {
        const { data } = await useFetch(`/api/wp/parks/parks`, {
            query: {
                acf_format: "standard",
                park_ids: parkIds,
            },
        });

        parks.value = data.value;
    };

    function stripBaseUrl(url = null) {
        if (url) {
            return url?.replace(/^https?:\/\/[^/]+/, "");
        }

        return url;
    }

    await getParks(parkIds);

    // console.log("Data: ", props.componentData);
    // console.log("Parks: ", parks.value);
</script>
